
import React from 'react'
import { navigate } from 'gatsby'
// import { getFunctions, httpsCallable } from 'firebase/functions'
import { useRecoilValue } from 'recoil'

import Meta from 'layout/Meta'
import getFunction from 'utils/functions'
import useStatus from 'utils/useStatus'
import Spinner from 'utils/Spinner'
import SignInEmail from 'user/SignInEmail'
import SignUpEmail from 'user/SignUpEmail'
import SignInFacebook from 'user/SignInFacebook'

import { userAtom } from 'utils/atoms'


export default function InvitePage({ params }) {

	const code = params['*']
	const [status, setStatus] = useStatus()
	const [roomInfo, setRoomInfo] = React.useState(null)
	const { isAuth, isFacebookAuth } = useRecoilValue(userAtom)

	async function joinRoom() {
		try {
			setStatus(true)
			// const functions = getFunctions(firebase)
			// const joinRoomFunc = httpsCallable(functions, 'joinRoom')
			const joinRoomFunc = await getFunction('joinRoom')
			const response = await joinRoomFunc({ code })
			if (response.data?.joinRoom) {
				navigate(`/chat/${response.data.joinRoom}/`)
			} else {
				setStatus({ error: "Couldn't join room."})
			}
		} catch (error) { 
			console.error(error)
			setStatus({ error })
		}
	}

	React.useEffect(() => {
		async function getRoomInfo() {
			try {
				setStatus(true)
				// const functions = getFunctions(firebase)
				// const getRoomFunc = httpsCallable(functions, 'getRoom')
				const getRoomFunc = await getFunction('getRoom')
				const response = await getRoomFunc({ code })
				if (response?.data?.success) {
					setStatus({ success: true })
					setRoomInfo(response.data)
				} else if (response?.data?.notfound) {
					setStatus({ error: "Invalid invite code." })
					setRoomInfo(null)
				} else {
					setStatus({ error: "Couldn't fetch room info." })
				}
			} catch (error) { 
				console.error(error)
				setStatus({ error }) 
			}
		}
		getRoomInfo()
	}, [code, setStatus])

	return <main>

		<Meta title={!!roomInfo ? `Join "${roomInfo.name}" chat room` : "An invitation to join an anonymous chat room"} />

		{ (!roomInfo && status.working) && <Spinner>Fetching invite</Spinner> }

		{ !!roomInfo && <>

			<h1>Do you want to join<br /><em>{roomInfo.name}</em>?</h1>

			<p>There { roomInfo.users > 1 ? `are ${roomInfo.users} people` : "is one person" } in the room already.</p>

			{ roomInfo.requireFacebook && <>
				<p>You have to sign in with your Facebook profile to join the room.</p>
				<p>Everyone in the room will know who joined but the messages posted will be anonymous.</p>
			</> }

			{ (isAuth && !status.working && ((roomInfo.requireFacebook && isFacebookAuth) || !roomInfo.requireFacebook)) &&
				<p>
					<button onClick={() => joinRoom()} disabled={status.working}>
						Join room
					</button>
				</p>
			}

			{ status.working && <Spinner>Joining room</Spinner>}

			{ !isAuth && <>
				<SignInFacebook />
			</> }

			{ (!isAuth && !roomInfo.requireFacebook) && <>
				<SignInEmail />
				<SignUpEmail />
			</> }
		
		</>}

		{ status.error }

	</main>
}

